import request from '@/utils/request';
export var defaultAddressData = {
  id: 0,
  enabled: true,
  name: '',
  userId: 0,
  gstNumber: undefined,
  panNumber: undefined,
  fssaiNumber: undefined,
  line1: '',
  line2: '',
  countryId: null,
  country: {
    id: null
  },
  stateId: null,
  state: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  },
  pincodeId: null,
  pincode: {
    id: null
  }
};
export var getAddresses = function getAddresses(params) {
  return request({
    url: '/addresses',
    method: 'get',
    params: params
  });
};
export var getAddressesById = function getAddressesById(id) {
  return request({
    url: "/addresses/".concat(id),
    method: 'get'
  });
};
export var updateAddresses = function updateAddresses(id, data) {
  return request({
    url: "/addresses/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteAddresses = function deleteAddresses(id) {
  return request({
    url: "/addresses/".concat(id),
    method: 'delete'
  });
};
export var createAddresses = function createAddresses(data) {
  return request({
    url: '/addresses/',
    method: 'post',
    data: data
  });
};